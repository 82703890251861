import { useLocation } from '@gatsbyjs/reach-router';
import { Link } from 'gatsby';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import logoPath from '../assets/images/logo.svg';
import { ApiContext } from '../shared/api/api-provider';
import { CartContext } from '../shared/cart/cart-provider';
import classes from '../shared/classes';
import Announcement from './announcement/announcement';
import CartDrawer from './cart-drawer/cart-drawer';
import './header.scss';
import MenuDrawer from './menu-drawer/menu-drawer';
import SearchForm from './search-form/search-form';
import CartDrawerNotification from './cart-drawer-notification/cart-drawer-notification';
import { CartItem } from '../shared/api/types';

const NOTIFICATION_TIMEOUT = 3000;

export default function Header(): ReactElement {
  const [menuVisible, setMenuVisible] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [notificationItem, setNotificationItem] = useState<CartItem>();
  const notificationItemTimeout = useRef<NodeJS.Timeout>();

  const shopButtonRef = useRef<HTMLButtonElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const cartButtonRef = useRef<HTMLButtonElement>(null);

  const { authenticated } = useContext(ApiContext);
  const { cart, newestItem } = useContext(CartContext);

  const { pathname } = useLocation();
  const locationShop = useMemo(() => (
    pathname.includes('brands') || pathname.includes('products') || pathname.includes('categories')
  ), [pathname]);
  const locationAccount = useMemo(() => pathname.includes('account'), [pathname]);

  useEffect(() => {
    // Retrieve the lastDismissedItem from local storage and parse it
    const storedLastDismissedItem = JSON.parse(localStorage.getItem('lastDismissedItem') || '{}');

    // Show notification if newestItem is different from the stored lastDismissedItem
    if (newestItem?.key !== storedLastDismissedItem.key || newestItem?.quantity !== storedLastDismissedItem.count) {
      setNotificationItem(newestItem);
    }
  }, [newestItem]);

  const toggleMenu = useCallback(() => {
    setMenuVisible((prevState) => !prevState);
  }, []);

  const renderChildName = useCallback(() => {
    if(!locationShop) {
      return null;
    }

    return (
      <div className="site-nav__child-item">
        {pathname.endsWith('products') && 'Alles'}
        {pathname.endsWith('brands/knut-hansen') && (
          <span className="text-uppercase">Knut Hansen</span>
        )}
        {pathname.endsWith('brands/ron-piet') && (
          <span className="text-uppercase">Ron Piet</span>
        )}
        {pathname.endsWith('merch') && 'Merch'}
      </div>
    );
  }, [pathname, locationShop]);

  const removeNewestItem = useCallback(() => {
    // Store the newestItem as the lastDismissedItem in local storage
    localStorage.setItem('lastDismissedItem', JSON.stringify({
      key: newestItem?.key,
      count: newestItem?.quantity
    }));

    // Hide the notification
    setNotificationItem(undefined);
  }, [newestItem]);

  return (
    <>
      <Announcement />

      <header role="banner" className="site-header">
        <div className="block block--align-center block--justify-between block__child--grow px-5 px-8--lg">
          <nav role="navigation" className="site-nav block block--align-center">
            <Link to="/" className="site-logo site-nav__item">
              <img src={logoPath} alt="HDC" className="site-logo__image" />
            </Link>
            <div className="hidden-until--sm">
              <button
                ref={shopButtonRef}
                type="button"
                className={classes('site-nav__item button button--unstyled', {
                  'site-nav__item--active': menuVisible || locationShop,
                })}
                onClick={setMenuVisible.bind(null, true)}
              >
                Shop
                {renderChildName()}
              </button>

              <Link
                to="/about"
                className="site-nav__item button button--unstyled"
                activeClassName="site-nav__item--active"
              >
                Über uns
              </Link>
            </div>
          </nav>

          <div className="block">
            <SearchForm className="hidden-until--sm" />

            <nav className="symbol-nav ml-7--md ml-9--lg ml-11--xl">
              <Link
                to="/account"
                className={classes('symbol-nav__item', {
                  'symbol-nav__item--decorated symbol-nav__dot': !!authenticated,
                })}
              >
                {locationAccount ? (
                  <i className="icon icon--user-solid">Profil</i>
                ) : (
                  <i className="icon icon--user">Profil</i>
                )}
              </Link>

              <button
                ref={cartButtonRef}
                type="button"
                className={classes('symbol-nav__item button button--unstyled', {
                  'symbol-nav__item--decorated': !!cart?.items_count,
                })}
                onClick={setCartVisible.bind(null, true)}
              >
                {cartVisible ? (
                  <i className="icon icon--basket-solid">Korb</i>
                ) : (
                  <i className="icon icon--basket">Korb</i>
                )}
                {!!cart?.items_count && (
                  <span className="symbol-nav__cart-count">
                    {cart?.items_count < 10 ? '0' : ''}
                    {cart?.items_count}
                  </span>
                )}
              </button>

              <button
                ref={menuButtonRef}
                type="button"
                className="symbol-nav__item button button--unstyled hidden--md"
                onClick={toggleMenu}
              >
                {menuVisible ? (
                  <i className="icon icon--times">Schließen</i>
                ) : (
                  <i className="icon icon--bars">Menü</i>
                )}
              </button>
            </nav>
          </div>
        </div>
      </header>

      {menuVisible && (
        <MenuDrawer
          triggerRefs={[shopButtonRef, menuButtonRef]}
          onClose={setMenuVisible.bind(null, false)}
        />
      )}
      {cartVisible && (
        <CartDrawer
          triggerRefs={[cartButtonRef]}
          onClose={setCartVisible.bind(null, false)}
        />
      )}

      {notificationItem && (
        <CartDrawerNotification removeNewestItem={removeNewestItem} item={notificationItem} />
      )}
    </>
  );
}
