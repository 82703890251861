import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { ReactElement, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ApiContext, STORE_API_BASE } from '../api/api-provider';
import { Address, Billing, CartData, CartItem, CheckoutData, Order, PaymentData, PaymentGateway, PaymentMethod, StoreApiError } from '../api/types';
import { handleApiError } from '../api/utils';
import useTracking from '../tracking/use-tracking';

export const QUANTITY_MIN = 1;
export const QUANTITY_MAX = 99;

type CheckoutArgs = {
  billing: Partial<Billing>;
  shipping: Partial<Address>;
  paymentGateway: PaymentGateway;
  paymentData?: PaymentData;
};

type ErrorResponse = {
  error: string;
};

export type CartContextValue = {
  cart?: CartData;
  newestItem?: CartItem;
  fetch: () => Promise<AxiosResponse<CartData, unknown> | undefined>;
  addItem: (id: number, quantity: number) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  updateItem: (key: string, quantity: number) => Promise<void>;
  clearItems: () => Promise<void>;
  addCoupon: (code: string) => Promise<void>;
  removeCoupon: (code: string) => Promise<void>;
  clearCoupons: () => Promise<void>;
  clearCart: () => Promise<void>;
  updateCustomer: (billing?: Partial<Billing>, shipping?: Partial<Address>) => Promise<void>;
  draftOrder: () => Promise<CheckoutData | undefined>;
  updateOrderWithPaypalOrderId: (id: string, paypalOrderId: string) => Promise<Order>;
  checkout: (args: CheckoutArgs) => Promise<CheckoutData | undefined>;
  createPaypalOrderDetails: (cart: CartData, orderId: string, paymentMethod: PaymentMethod) => any;
  confirmOrder: (orderId: string, paypalOrderId: string) => Promise<Order | undefined>; 
  fetchOrderData: (orderId: string | null, firstName: string | null, lastName: string | null) => Promise<Order | undefined>;
};

interface LooseObject {
  [key: string]: any
}

export const CartContext = createContext<CartContextValue>({
} as CartContextValue);

type Props = {
  children: ReactNode;
};

export default function CartProvider({ children }: Props): ReactElement {
  const [data, setData] = useState<CartData>();
  const [newestItem, setNewestItem] = useState<CartItem>();
  const nonceRef = useRef<string>();

  const { userId, client } = useContext(ApiContext);

  const {
    getTrackingItem,
    getTrackingItems,
    getTrackingCoupons,
    getTrackingPrice,
    trackAddItem,
    trackPurchase,
  } = useTracking();

  const fetch = useCallback(async (): Promise<AxiosResponse<CartData, unknown> | undefined> => {
    try {
      const response = await client.get<CartData>(`${STORE_API_BASE}/cart`);
      setData(response.data);

      return response;
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client]);

  const extractNonce = useCallback((response?: AxiosResponse<unknown, unknown>) => {
    if(!response)  return;

    nonceRef.current = response?.headers.nonce;
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const response = await fetch();
        extractNonce(response);
      } catch(error) {
        await handleApiError(error as AxiosError<StoreApiError>);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getRequestConfig = useCallback((): AxiosRequestConfig<unknown> => {
    return {
      headers: {
        Nonce: nonceRef.current,
      },
    };
  }, []);

  const getItem = useCallback((id: number, items: CartItem[]) => {
    return items.find((item) => item.id === id);
  }, []);

  const addItem = useCallback(async (id: number, quantity: number) => {
    let item: CartItem | undefined;

    try {
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/add-item`, {
        id,
        quantity,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);

      item = getItem(id, response.data.items);
      setNewestItem(item);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }

    try {
      if(item) {
        trackAddItem(getTrackingItem(item));
      }
    } catch(error) {
      console.error(error);
    }
  }, [client, extractNonce, getItem, getRequestConfig, getTrackingItem, trackAddItem]);

  const removeItem = useCallback(async (key: string) => {
    try {
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/remove-item`, {
        key,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const updateItem = useCallback(async (key: string, quantity: number) => {
    try {
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/update-item`, {
        key,
        quantity,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const clearItems = useCallback(async () => {
    try {
      const response = await client.delete(
        `${STORE_API_BASE}/cart/items`,
        getRequestConfig(),
      );

      extractNonce(response);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const addCoupon = useCallback(async (code: string) => {
    try {
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/apply-coupon`, {
        code,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const removeCoupon = useCallback(async (code: string) => {
    try {
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/remove-coupon`, {
        code,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const clearCoupons = useCallback(async () => {
    try {
      const response = await client.delete(
        `${STORE_API_BASE}/cart/coupons`,
        getRequestConfig(),
      );

      extractNonce(response);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const clearCart = useCallback(async () => {
    await clearItems();
    await clearCoupons();

    await fetch();
  }, [clearCoupons, clearItems, fetch]);

  const updateCustomer = useCallback(async (
    billing?: Partial<Billing>,
    shipping?: Partial<Address>,
  ) => {
    try{
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/update-customer`, {
        billing_address: billing,
        shipping_address: shipping,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const draftOrder = useCallback(async () => {
    try {
      const response = await client.get<CheckoutData>(
        `${STORE_API_BASE}/checkout`,
        getRequestConfig(),
      );

      extractNonce(response);
      return response.data;
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const fetchOrderData = useCallback(async (orderId: string | null, firstName: string | null, lastName: string | null) => {
    try {
        const response = await client.get<Order>(
            `/hdc/v1/order-summary/${orderId}?first_name=${firstName}&last_name=${lastName}`,
            getRequestConfig(),
        );

        extractNonce(response);
        return response.data;
    } catch (error) {
        await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const confirmOrder = async (orderId: string, paypalOrderId: string): Promise<Order | undefined> => {
    const config = getRequestConfig();

    try {
      const response = await client.post<Order>(`/hdc/v1/confirm-payment/${orderId}`, {
        meta_data: [{
          key: '_ppcp_paypal_order_id',
          value: paypalOrderId,
        }],
      }, config);
  
      if (response.status !== 200) {
        throw new Error(`Server responded with status code: ${response.status}`);
      }

      try {
        trackPurchase({
          orderNumber: Number(orderId),
          items: getTrackingItems(data?.items),
          coupons: getTrackingCoupons(data?.coupons),
          total: getTrackingPrice(data?.totals.total_price, data?.totals),
        });
        console.log("Tracking purchase")
        console.log(data)
      } catch(error) {
        console.error(error);
      }

  
      return response.data;
    } catch (error) {
      console.error("Error confirming order:", error);
      return undefined;
    }
  };
  

  const updateOrderWithPaypalOrderId = useCallback(async (
    id: string,
    paypalOrderId: string,
  ): Promise<Order | undefined> => {
    const config = getRequestConfig();
  
    // Check if the Nonce header is correctly set
    if (!config.headers || !config.headers['Nonce']) {
      throw new Error("Nonce header is missing or not correctly set.");
    }
  
    // Check if order ID and PayPal order ID are provided
    if (!id || !paypalOrderId) {
      throw new Error("Order ID or PayPal order ID is missing.");
    }
  
    try {
      const response = await client.post<Order | ErrorResponse>(`/hdc/v1/store-paypal-id/${id}`, {
        paypal_payment_id: paypalOrderId,
      }, config);
  
      if (response.status !== 200 || 'error' in response.data) {
        throw new Error(`Server responded with status code: ${response.status} or error in data.`);
      }
  
      return response.data as Order;
    } catch (error) {
      await handleApiError(error as AxiosError<StoreApiError>);
      return undefined;
    }
  }, [client, getRequestConfig]);
  

  function convertToMainUnit(priceInSmallestUnit: string | number): string {
    return (parseFloat(priceInSmallestUnit.toString()) / 100).toFixed(2);
  }

  const createPaypalOrderDetails = (cart: CartData, orderId: string, paymentMethod: PaymentMethod) => {
    if (!cart || !cart.items || cart.items.length === 0) {
      throw new Error("Cart is empty or invalid.");
    }
  
    const items = cart.items.map(item => ({
      name: item.name,
      unit_amount: {
        currency_code: cart.totals.currency_code,
        value: convertToMainUnit(item.prices.price), // Adjust this if the structure is different
      },
      quantity: item.quantity.toString(),
    }));
  
    const shippingAddress = cart.shipping_address ? {
      name: {
        full_name: `${cart.shipping_address.first_name} ${cart.shipping_address.last_name}`
      },
      address: {
        address_line_1: cart.shipping_address.address_1,
        address_line_2: cart.shipping_address.address_2 || '',  // Ensure non-null value
        admin_area_2: cart.shipping_address.city,
        postal_code: cart.shipping_address.postcode,
        country_code: cart.shipping_address.country,
      }
    } : undefined;

    const billingAddress = cart.billing_address ? {
      address_line_1: cart.billing_address.address_1,
      address_line_2: cart.billing_address.address_2 || '',  // Ensure non-null value
      admin_area_2: cart.billing_address.city,
      postal_code: cart.billing_address.postcode,
      country_code: cart.billing_address.country,
    } : undefined;

    const orderDetails: LooseObject = {
      intent: "CAPTURE",
      purchase_units: [{
        reference_id: orderId,
        description: `Order ID: ${orderId}`,
        amount: {
          currency_code: cart.totals.currency_code,
          value: convertToMainUnit(cart.totals.total_price), // Adjust this if the structure is different
          breakdown: {
            item_total: {
              currency_code: cart.totals.currency_code,
              value: convertToMainUnit(parseFloat(cart.totals.total_items) + parseFloat(cart.totals.total_items_tax)), // Adjust this if the structure is different
            },
            shipping: {
              currency_code: cart.totals.currency_code,
              value: convertToMainUnit(cart.totals.total_shipping), // Adjust this if the structure is different
            },
            discount: {
              currency_code: cart.totals.currency_code,
              value: convertToMainUnit(parseFloat(cart.totals.total_discount) + parseFloat(cart.totals.total_discount_tax)), // Adjust this if the structure is different
            },
            // tax_total: {
            //   currency_code: cart.totals.currency_code,
            //   value: convertToMainUnit(cart.totals.total_items_tax), // Adjust this if the structure is different
            // }
          }
        },
        items: items,
        shipping: shippingAddress,
      }],
    }

    if (paymentMethod === "card") {
      orderDetails.payer = {
        name: {
          given_name: cart.shipping_address.first_name,
          surname: cart.shipping_address.last_name
        },
        address: billingAddress,
        email_address: cart.billing_address.email
      }
    }

    console.log(cart.totals)

    console.log(orderDetails)
  
    return orderDetails;
  };
  
  const checkout = useCallback(async ({
    billing,
    shipping,
    paymentGateway,
    paymentData,
  }: CheckoutArgs) => {
    let response;

    try {
      response = await client.post<CheckoutData>(`${STORE_API_BASE}/checkout`, {
        billing_address: billing,
        shipping_address: shipping,
        payment_method: paymentGateway,
        payment_data: paymentData,
      }, getRequestConfig());

      extractNonce(response);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }

    // try {
    //   if(response) {
    //     trackPurchase({
    //       orderNumber: response.data.order_id,
    //       items: getTrackingItems(data?.items),
    //       coupons: getTrackingCoupons(data?.coupons),
    //       total: getTrackingPrice(data?.totals.total_price, data?.totals),
    //     });
    //   }
    // } catch(error) {
    //   console.error(error);
    // }

    return response?.data;
  }, [
    client,
    data,
    extractNonce,
    getRequestConfig,
    getTrackingCoupons,
    getTrackingItems,
    getTrackingPrice,
    trackPurchase,
  ]);

  const value = useMemo(() => ({
    cart: data,
    newestItem,
    fetch,
    addItem,
    removeItem,
    updateItem,
    clearItems,
    addCoupon,
    removeCoupon,
    clearCoupons,
    clearCart,
    updateCustomer,
    draftOrder,
    updateOrderWithPaypalOrderId,
    createPaypalOrderDetails,
    confirmOrder,
    checkout,
    fetchOrderData
  } as CartContextValue), [
    data,
    newestItem,
    fetch,
    addItem,
    removeItem,
    updateItem,
    clearItems,
    addCoupon,
    removeCoupon,
    clearCoupons,
    clearCart,
    updateCustomer,
    draftOrder,
    updateOrderWithPaypalOrderId,
    createPaypalOrderDetails,
    confirmOrder,
    checkout,
    fetchOrderData
  ]);

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
}
