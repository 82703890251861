import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactElement } from 'react';
import './announcement.scss';

export default function Announcement(): ReactElement {
  const { wp } = useStaticQuery<Queries.AnnouncementQuery>(graphql`query Announcement {
    wp {
      hdcAnnouncement {
        announcementSettings {
          image {
            sourceUrl
          }
        }
      }
    }
  }`);

  return (
    <div
      className="site-announcement"
      style={{
        backgroundImage: `url('${wp?.hdcAnnouncement?.announcementSettings?.image?.sourceUrl}')`,
      }}
    ></div>
  );
}
