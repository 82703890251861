import { AxiosError } from 'axios';
import { AccountData, Address, ApiError, Billing, ItemDataEntry, OrderStatus, PaymentMethod, RestApiError, StoreApiError } from './types';

export const ORDER_STATUS_LABELS = {
  [OrderStatus.Cancelled]: 'Stoniert',
  [OrderStatus.Completed]: 'Abgeschlossen',
  [OrderStatus.Failed]: 'Fehlerhaft',
  [OrderStatus.OnHold]: 'Wartend',
  [OrderStatus.Pending]: 'Wartend',
  [OrderStatus.Processing]: 'Abwicklung',
  [OrderStatus.Refunded]: 'Erstattet',
  [OrderStatus.Trash]: 'Papierkorb',
};

export const PAYMENT_METHOD_LABELS = {
  [PaymentMethod.Card]: 'Kreditkarte',
  [PaymentMethod.PayPal]: 'PayPal',
  [PaymentMethod.Sofort]: 'Sofort',
};

export function handleApiError(error: AxiosError<StoreApiError | RestApiError>): Promise<ApiError> {
  let message: string;
  const restApiError = error.response?.data as RestApiError | undefined;
  const storeApiError = error.response?.data as StoreApiError | undefined;

  if(restApiError?.error) {
    message = restApiError.error;
  } else if (storeApiError?.data) {
    const params = storeApiError.data.params;
    message = params ? Object.values(params).join('; ') : storeApiError.message;
  } else {
    message = error.message;
  }

  return Promise.reject({
    message,
  });
}

export function sanitizeAddress(data: Partial<Address>): Partial<Address | Billing> {
  return Object.entries(data).reduce((prev, [key, value]) => {
    if(value) {
      return {
        ...prev,
        [key]: value,
      };
    }

    return prev;
  }, {});
}

export function sanitizeAccountData(data: AccountData): Partial<AccountData> {
  return {
    email: data.email,
    billing: {
      ...sanitizeAddress(data.billing),
    },
    shipping: {
      ...sanitizeAddress(data.shipping),
    },
  };
}

export function getItemData(
  data: ItemDataEntry[] | undefined,
  type: string,
): ItemDataEntry | undefined {
  return data?.find((entry) => entry.type === type);
}
