export type Optional<T> = T | null | undefined;

export type ApiError = {
  message: string;
};

export type StoreApiError = {
  data: {
    params: Record<string, string>;
  };
  message: string;
};

export type RestApiError = {
  error: string;
};

export type Address = {
  first_name: string;
  last_name: string;
  company: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
};

export enum AddressType {
  Billing = 'billing',
  Shipping = 'shipping',
}

export type Billing = Address & {
  email: string;
};

export type AccountData = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  billing: Partial<Billing>;
  shipping: Partial<Address>;
};

export type AccountFormData = Partial<AccountData & {
  password: string;
}>;

export type SignupFormData = Partial<{
  username: string;
  password: string;
  email: string;
  first_name: string;
  last_name: string;
  billing: Partial<Address>;
}>;

export type Currency = {
  currency_code: string;
  currency_symbol: string;
  currency_minor_unit: number;
  currency_decimal_separator: string;
};

export type Coupon = {
  code: string;
  totals: Currency & {
    total_discount: string;
    total_discount_tax: string;
  };
};

export type ShippingRate = {
  package_id: number;
  name: string;
  shipping_rates: Currency & {
    rate_id: string;
    name: string;
    price: string;
    selected: boolean;
  }[];
};

export type StoreProduct = {
  id: number;
  name: string;
  permalink: string;
  sku: string;
  short_description: string;
  is_in_stock: boolean;
  categories: {
    id: number;
    name: string;
    slug: string;
  }[];
  prices: {
    currency_code: string;
    currency_symbol: string;
    currency_minor_unit: number;
    currency_decimal_separator: string;
    currency_thousand_separator: string;
    currency_prefix: string;
    currency_suffix: string;
    price: string;
    regular_price: string;
    sale_price: string;
  };
  on_sale: boolean;
  images: {
    id: number,
    src: string,
    thumbnail: string;
    srcset: string;
    sizes: string;
    name: string;
    alt: string;
  }[];
};

export type ProductImage = {
  id: number;
  src: string;
  srcset: string;
  sizes: string;
  name: string;
  alt: string;
};

export type ItemDataEntry = {
  type: 'brand' | 'taxonomy' | 'variation';
  [key: string]: string;
};

export type CartItem = {
  key: string;
  id: number;
  sku: string;
  quantity: number;
  name: string;
  permalink: string;
  summary: string;
  short_description: string;
  images: ProductImage[];
  item_data: ItemDataEntry[];
  variation: {
    attribute: string;
    value: string;
  }[];
  prices: Currency & {
    price: string;
  };
  totals: Currency & {
    line_subtotal: string;
    line_total: string;
    line_total_tax: string;
  };
};

export type CartData = {
  coupons: Coupon[];
  shipping_rates: ShippingRate[],
  billing_address: Billing,
  shipping_address: Address,
  items: CartItem[],
  items_count: number;
  totals: Currency & {
    total_items: string;
    total_items_tax: string;
    total_discount: string;
    total_discount_tax: string;
    total_shipping: string;
    total_price: string;
  };
};

export enum OrderStatus {
  Pending = 'pending',
  Processing = 'processing',
  OnHold = 'on-hold',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Refunded = 'refunded',
  Failed = 'failed',
  Trash = 'trash',
}

export enum PaymentMethod {
  Card = 'card',
  PayPal = 'paypal',
  Sofort = 'sofort',
}

export enum PaymentGateway {
  PayPal = 'ppcp-gateway',
}

export type LineItem = {
  name:string;
  product_id: number;
  variation_id: number;
  quantity: number;
  subtotal: string;
  total: string;
  price: number;
  item_data: {
    [key: string]: ItemDataEntry;
  };
  image: {
    id: string;
    src: string;
  };
};

export type CouponLine = {
  code:string;
  discount: string;
};

export type Order = {
  id: number;
  number: string;
  status: OrderStatus;
  total: string;
  currency_symbol: string;
  date_created: string;
  line_items: LineItem[];
  coupon_lines: CouponLine[];
  billing: Billing;
  shipping: Address;
  shipping_total: string;
  payment_method: PaymentMethod;
};

export type CheckoutData = {
  order_id: number;
  status: OrderStatus;
  customer_note: string;
  customer_id: number;
  billing_address: Billing;
  shipping_address: Address;
  payment_method: PaymentMethod;
  payment_result: {
    payment_status: string;
    payment_details: unknown[];
    redirect_url: string;
  }
};

export enum PaymentStatus {
  Pristine = 'pristine',
  Started = 'started',
  Processing = 'processing',
  Error = 'has_error',
  Failed = 'failed',
  Success = 'success',
  Complete = 'complete',
}

export type PaymentData = {
  key: string;
  value: string;
}[];
