import { navigate } from 'gatsby';
import React, { ReactElement, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import classes from '../../shared/classes';
import { SearchParams } from '../../shared/params';
import './search-form.scss';

type SearchFormData = {
  query: string;
};

export enum SearchFormVariant {
  White = 'white',
}

type Props = {
  variant?: SearchFormVariant;
  grow?: boolean;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSubmit?: Function;
};

export default function SearchForm({
  variant,
  grow,
  className,
  onSubmit,
}: Props): ReactElement {
  const {
    register,
    handleSubmit,
  } = useForm<SearchFormData>();

  const submit = useCallback(({ query }: SearchFormData) => {
    if(!query) return;

    const searchParams = new URLSearchParams();
    searchParams.set(SearchParams.Query, query);

    onSubmit?.();

    navigate(`/search?${searchParams.toString()}`);
  }, [onSubmit]);

  return (
    <form
      className={classes('block', className, {
        'fg-white': variant === SearchFormVariant.White,
      })}
      onSubmit={handleSubmit(submit)}
    >
      <fieldset
        className={classes('search__query form__field', {
          'form__field--white': variant === SearchFormVariant.White,
          'block__child--grow': !!grow,
        })}
      >
        <button
          type="submit"
          className="button button--unstyled border-bottom"
        >
          <i className="icon icon--magnifier">Search</i>
        </button>

        <input
          type="text"
          className="control pl-2"
          {...register('query', { required: true })}
        />
      </fieldset>
    </form>
  );
}
