import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler, ReactElement, RefObject } from 'react';
import classes from '../../shared/classes';
import Drawer, { DrawerSide } from '../drawer/drawer';
import SearchForm, { SearchFormVariant } from '../search-form/search-form';
import './menu-drawer.scss';

type Props = {
  triggerRefs: RefObject<HTMLElement>[];
  onClose: MouseEventHandler<unknown>;
};

export default function MenuDrawer({ triggerRefs, onClose }: Props): ReactElement {
  const data = useStaticQuery<Queries.MenuQuery>(graphql`query Menu {
    allWpBrand(sort: {menuOrder: ASC}) {
      nodes {
        title
        slug
      }
    }
  }`);

  const entries: (Queries.MenuQuery['allWpBrand']['nodes'][number] & {
    url?: string;
    className?: string;
  })[] = [
    ...data.allWpBrand.nodes,
    {
      title: 'Merch',
      slug: null,
      url: '/categories/merch',
    },
    {
      title: 'Alles',
      slug: null,
      url: '/products',
    },
  ];

  return (
    <Drawer
      className="menu-drawer"
      side={DrawerSide.Left}
      triggerRefs={triggerRefs}
      onClickOutside={onClose}
    >
      <header className="menu-drawer__header hidden-until--sm">
        <button
          type="button"
          className="button button--unstyled"
          onClick={onClose}
        >
          <i className="icon icon--times">Schließen</i>
        </button>
      </header>

      <nav className="menu-drawer__body">
        <ul className="menu-drawer__items list--unstyled">
          <li className="menu-drawer__category">
            <header className="menu-drawer__category-header hidden--md">
              <Link to="/products" onClick={onClose}>
                <span className="menu-drawer__item-text">Shop</span>
              </Link>
            </header>

            <ul className="list--unstyled">
              {entries.map(({ title, slug, url, className }) => (
                <li key={title} className={classes('menu-drawer__item', className)}>
                  <Link
                    to={url || `/brands/${slug}`}
                    className="text-heading text-xxxl text-uppercase"
                    onClick={onClose}
                  >
                    <span className="menu-drawer__item-text">{title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>

          <li className="menu-drawer__item hidden--md">
            <Link to="/about" onClick={onClose}>
              <span className="menu-drawer__item-text">Über uns</span>
            </Link>
          </li>
        </ul>
      </nav>

      <footer className="menu-drawer__footer hidden--md">
        <SearchForm
          variant={SearchFormVariant.White}
          onSubmit={onClose}
          grow
        />
      </footer>
    </Drawer>
  );
}
