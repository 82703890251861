import React, { ReactElement, useContext, useEffect } from 'react';
import CartDrawerItem from '../cart-drawer-item/cart-drawer-item';
import { CartContext } from '../../shared/cart/cart-provider';
import './cart-drawer-items.scss';

export default function CartDrawerItems(): ReactElement {
  const { cart, fetch } = useContext(CartContext);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        await fetch();
      } catch {}
    })();
  }, [fetch]);

  return (
    <div className="block block--vertical">
      {cart?.items.map((item) => (
        <CartDrawerItem
          key={item.id}
          itemKey={item.key}
          name={item.name}
          short_description={item.short_description}
          item_data={item.item_data}
          variation={item.variation}
          images={item.images}
          permalink={item.permalink}
          quantity={item.quantity}
          totals={item.totals}
          className="cart__item"
        />
      ))}
    </div>
  );
}
