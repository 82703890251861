import { Link } from 'gatsby';
import React, { MouseEventHandler, ReactElement, RefObject, useContext } from 'react';
import CartDrawerItems from '../cart-drawer-items/cart-drawer-items';
import { CartContext } from '../../shared/cart/cart-provider';
import Price, { addToPrice } from '../../shared/price/price';
import Drawer, { DrawerSide } from '../drawer/drawer';
import './cart-drawer.scss';

type Props = {
  triggerRefs: RefObject<HTMLElement>[];
  onClose: MouseEventHandler<unknown>;
};

export default function CartDrawer({ triggerRefs, onClose }: Props): ReactElement {
  const { cart } = useContext(CartContext);

  return (
    <Drawer
      className="cart-drawer pb-6"
      side={DrawerSide.Right}
      triggerRefs={triggerRefs}
      onClickOutside={onClose}
    >
      <header className="flex w-full justify-between sm:px-9 pb-3 pt-2 px-4 sm:mt-4">
        <h1 className='sm:text-xl text-md'>Korb</h1>
        <button type="button" className="button button--unstyled" onClick={onClose}>
          <i className="icon icon--times">Schließen</i>
        </button>
      </header>

      <div className="cart-drawer__body py-4">
        <CartDrawerItems />
      </div>

      <footer className="px-4 sm:px-9">
        <div className='border-top pt-4'>
          <span className="text-bold text-lg mr-3">
            <Price
              currency={cart?.totals}
              value={addToPrice(cart?.totals.total_items, cart?.totals.total_items_tax)}
            />
          </span>
          inkl. MwSt.
        </div>
        <div className="mt-3">
          +
          {' '}
          <Price currency={cart?.totals} value={cart?.totals.total_shipping} />
          {' '}
          Versandkosten
        </div>

        <div className="text-right mt-4 mb-6">
          {!!cart?.items_count && (
          <Link
            to="/checkout/customer"
            className="button button--beige-light"
            onClick={onClose}
          >
            Zur Kasse
          </Link>
          )}
        </div>

      </footer>
    </Drawer>
  );
}
