import { useEffect, useState } from 'react';

export const LIFTED_ELEMENT_ID = 'lifted-content';

export default function useLiftedContent(): Element | undefined {
  const [element, setElement] = useState<Element>();

  useEffect(() => {
    setElement(document.querySelector(`#${LIFTED_ELEMENT_ID}`) ?? undefined);
  }, []);

  return element;
}
