import { PurchaseEvent } from './types';

export enum PageType {
  KnutHansen = 'category_knuthansen',
  RonPiet = 'category_ronpiet',
  Merch = 'category_merch',
  All = 'category_alles',
}

export default function uppr(event: PurchaseEvent): void {
  if(typeof eamTrckAddBasketItem === 'undefined' || typeof eamTrckSubmitBasket === 'undefined') {
    return;
  }

  let emid = '';
  emid = emid || window.localStorage[emid];

  event.items.forEach((item) => {
    eamTrckAddBasketItem(
      Number(process.env.GATSBY_UPPR_CAMPAIGN_ID) || -1,
      event.orderNumber,
      Number(item.taxonomies?.uppr[0]) || -1,
      item.itemNumber,
      item.quantity,
      `${item.price}`,
      emid,
      item.name,
      item.category,
    );
  });

  event.coupons.forEach((coupon) => {
    eamTrckAddBasketItem(
      Number(process.env.GATSBY_UPPR_CAMPAIGN_ID) || -1,
      event.orderNumber,
      1,
      coupon.code,
      1,
      `-${coupon.discount}`,
      emid,
      'Voucher',
      'Voucher',
      {
        vc: coupon.code,
      },
    );
  });

  eamTrckSubmitBasket();
}
