exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account/[...].tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-brands-wp-brand-slug-tsx": () => import("./../../../src/pages/brands/{WpBrand.slug}.tsx" /* webpackChunkName: "component---src-pages-brands-wp-brand-slug-tsx" */),
  "component---src-pages-categories-wp-product-category-slug-tsx": () => import("./../../../src/pages/categories/{WpProductCategory.slug}.tsx" /* webpackChunkName: "component---src-pages-categories-wp-product-category-slug-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout/[...].tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-common-wp-page-slug-tsx": () => import("./../../../src/pages/common/{WpPage.slug}.tsx" /* webpackChunkName: "component---src-pages-common-wp-page-slug-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact/[...].tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-password-lost-tsx": () => import("./../../../src/pages/password/lost/[...].tsx" /* webpackChunkName: "component---src-pages-password-lost-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password/reset/[...].tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-wp-product-slug-tsx": () => import("./../../../src/pages/products/{WpProduct.slug}.tsx" /* webpackChunkName: "component---src-pages-products-wp-product-slug-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-success-index-tsx": () => import("./../../../src/pages/success/index.tsx" /* webpackChunkName: "component---src-pages-success-index-tsx" */)
}

