import { useRef, useEffect } from 'react';

type Props = {
  src: string;
  referrerPolicy?: string;
};

export default function UpdatableScript({ src, referrerPolicy }: Props): null {
  const elementRef = useRef<HTMLScriptElement>();

  useEffect(() => {
    if(elementRef.current) {
      elementRef.current.remove();
    }

    elementRef.current = document.createElement('script');
    elementRef.current.src = src;
    elementRef.current.referrerPolicy = referrerPolicy ?? '';

    const body = document.querySelector('body');
    body?.appendChild(elementRef.current);
  }, [src, referrerPolicy]);

  return null;
}
