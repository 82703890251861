import React, { ReactElement, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import classes from '../../classes';
import './number-control.scss';

export enum NumberControlBackground {
  White = 'white',
  Beige = 'beige',
}

export type Props = {
  id: string;
  required?: boolean;
  min?: number;
  max?: number;
  placeholder?: string;
  small?: boolean;
  background?: NumberControlBackground;
  className?: string;
};

export default function NumberControl({
  id,
  required,
  min,
  max,
  placeholder,
  small,
  background,
  className,
}: Props): ReactElement {
  const {
    register,
    watch,
    setValue,
    formState: {
      errors,
    },
  } = useFormContext();

  const value = watch(id);

  const increase = useCallback(() => {
    const newValue = Number(value) + 1;
    if(max && newValue > max) return;

    setValue(id, newValue);
  }, [id, max, setValue, value]);

  const decrease = useCallback(() => {
    const newValue = Number(value) - 1;
    if(min && newValue < min) return;

    setValue(id, newValue);
  }, [id, min, setValue, value]);

  return (
    <div
      className={classes('number-control', className, {
        'number-control--small': !!small,
        'bg-white': background === NumberControlBackground.White,
        'bg-beige-light': background === NumberControlBackground.Beige,
      })}
    >
      <button
        type="button"
        className="button button--unstyled"
        onClick={decrease}
      >
        <i
          className={classes('icon icon--minus', {
            'icon--small': !small,
            'icon--tiny': !!small,
          })}
        >
          Verringern
        </i>
      </button>

      <input
        type="number"
        className={classes('number-control__input', {
          'border-danger placeholder-danger': !!errors[id],
        })}
        placeholder={placeholder}
        min={min}
        max={max}
        {...register(id, {
          required,
          min,
          max,
        })}
      />

      <button
        type="button"
        className="button button--unstyled"
        onClick={increase}
      >
        <i
          className={classes('icon icon--plus', {
            'icon--small': !small,
            'icon--tiny': !!small,
          })}
        >
          Erhöhen
        </i>
      </button>
    </div>
  );
}
