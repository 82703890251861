import React, { ReactElement } from 'react';
import classes from './classes';

type Props = {
  html: string | null | undefined;
  wrapper?: string;
  className?: string;
};

export default function Rendered({ html, wrapper, className } : Props): ReactElement {
  return React.createElement(wrapper || 'div', {
    className: classes('rendered-body', className),
    dangerouslySetInnerHTML: {
      __html: html ?? '',
    },
  });
}
