import { useCallback, useEffect } from 'react';

export function useDocumentClick(
  listener: EventListener,
  enabled = true,
): void {
  const add = useCallback(() => {
    document.addEventListener('click', listener);
  }, [listener]);

  const remove = useCallback(() => {
    document.removeEventListener('click', listener);
  }, [listener]);

  useEffect(() => {
    if (enabled) {
      add();
    } else {
      remove();
    }

    return remove;
  }, [add, enabled, remove]);
}
