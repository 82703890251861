import React, { ButtonHTMLAttributes, MouseEventHandler, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classes from '../../shared/classes';
import './async-button.scss';

enum LoadingState {
  PROGRESS,
  SUCCESS,
  FAIL,
}

type Props = {
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  promise?: Promise<unknown>;
  onClick?: (event: Parameters<MouseEventHandler>[0]) => Promise<unknown>;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
};

const STATE_TIMEOUT = 1000;

export default function AsyncButton({
  type,
  promise,
  onClick,
  disabled,
  children,
  className,
}: Props): ReactElement {
  const [loading, setLoading] = useState<LoadingState>();
  const [loadingPromise, setLoadingPromise] = useState<Promise<unknown>>();
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setLoadingPromise(promise);
  }, [promise]);

  useEffect(() => {
    if(loading === LoadingState.FAIL || loading === LoadingState.SUCCESS) {
      if(timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(setLoading.bind(null, undefined), STATE_TIMEOUT);
    }

    return () => {
      if(timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [loading]);

  useEffect(() => {
    (async (): Promise<unknown> => {
      if(!loadingPromise) return;

      try {
        setLoading(LoadingState.PROGRESS);
        await loadingPromise;
        setLoading(LoadingState.SUCCESS);
      } catch {
        setLoading(LoadingState.FAIL);
      }
    })();
  }, [loadingPromise]);

  const handleClick = useCallback(async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if(!onClick) return;
    setLoadingPromise(onClick(event));
  }, [onClick]);

  return (
    <button
      type={type}
      className={classes('async-button button', className, {
        'async-button--loading': loading === LoadingState.PROGRESS,
        'button--success': loading === LoadingState.SUCCESS,
        'button--danger': loading === LoadingState.FAIL,
      })}
      disabled={loading === LoadingState.PROGRESS || disabled}
      onClick={handleClick}
    >
      <>
        <span className="async-button__body">
          {children}
        </span>

        {loading === LoadingState.PROGRESS && (
          <span className="async-button__loader">
            Lade
          </span>
        )}
      </>
    </button>
  );
}
