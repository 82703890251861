export default function classes(
  ...tokens: (string | Record<string, boolean> | undefined | null)[]
): string {
  let classList = '';

  tokens.forEach((token) => {
    if(token && typeof token === 'object') {
      Object.keys(token).forEach((key) => {
        if(token[key]) {
          classList += ` ${key}`;
        }
      });
    } else if(token) {
      classList += ` ${token}`;
    }
  });

  return classList;
}
