import { Link } from 'gatsby';
import React, { ReactElement, useCallback, useContext } from 'react';
import { CartItem as CartItemType } from '../../shared/api/types';
import CartItemForm from '../../shared/cart/ cart-item-form/cart-item-form';
import { CartContext } from '../../shared/cart/cart-provider';
import classes from '../../shared/classes';
import { NumberControlBackground } from '../../shared/form/number-control/number-control';
import Price, { addToPrice } from '../../shared/price/price';
import Rendered from '../../shared/rendered';
import './cart-drawer-item.scss';
import { getItemData } from '../../shared/api/utils';

type Props = Pick<CartItemType, 'name' | 'short_description' | 'images' | 'item_data' | 'quantity' | 'totals' | 'permalink' | 'variation'> & {
  itemKey: CartItemType['key'];
  className?: string;
};

export default function CartDrawerItem({
  itemKey,
  name,
  short_description,
  item_data,
  permalink,
  quantity,
  totals,
  images,
  variation,
  className,
}: Props): ReactElement {
  const { removeItem } = useContext(CartContext);

  const slug = permalink.split('/').pop();
  const brand = getItemData(item_data, 'brand');
  const attributes = variation.map((attribute) => attribute.value);

  const handleRemove = useCallback(async () => {
    await removeItem(itemKey);
  }, [itemKey, removeItem]);

  return (
    <article className={classes('block block--align-center block--justify-between', className)}>
      <div>
        <div className="block block--vertical">
          <div className="block block--vertical">
            <Link to={`/products/${slug}`} className="text-bold">
              <span className="text-uppercase">{brand?.title}</span>
              {' - '}
              {name}
              {!!attributes.length && ` - ${attributes.join(', ')}`}
            </Link>

            <div className="mt-1">
              <Rendered html={short_description} className="truncate-children--line" />
            </div>
          </div>

          <div className="block block--align-center mt-4">
            <CartItemForm
              itemKey={itemKey}
              storedQuantity={quantity}
              background={NumberControlBackground.Beige}
            />

            <div className="text-bold ml-4 mb-3">
              <Price
                value={addToPrice(totals.line_total, totals.line_total_tax)}
                currency={totals}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-2">
        <figure>
          <div className="">
            <img srcSet={images[0].srcset} alt={`${name} Bild`} className="cart-drawer-item__image" />
          </div>
        </figure>

        <button
          style={{fontSize: "14px"}}
          type="button"
          className="button button--unstyled text-sm opacity-50"
          onClick={handleRemove}
        >
          <i>Entfernen</i>
        </button>
      </div>
    </article>
  );
}
