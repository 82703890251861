export enum TrackingEventType {
  ViewCategory,
  ViewProduct,
  AddItem,
  Checkout,
  Purchase,
  Subscribe,
  Search,
}

export type ViewPageEvent = {
  slug: string;
};

type Product = {
  id: number;
  itemNumber: string;
  name: string;
  category: string;
  price: number;
  taxonomies?: { [key: string]: string[] }
};

export type ViewCategoryEvent = {
  name: string;
};

export type ViewProductEvent = Product;

export type TrackingItem = Product & {
  quantity: number;
};

export type TrackingCoupon = {
  code: string;
  discount: number;
};

export type AddItemEvent = TrackingItem;

export type CheckoutEvent = {
  items: TrackingItem[];
  total: number;
};

export type PurchaseEvent = CheckoutEvent & {
  coupons: TrackingCoupon[];
  orderNumber: number;
};

export type SearchEvent = {
  query: string;
};
