import axios, { AxiosError, AxiosInstance } from 'axios';
import React, { createContext, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import StorageKeys from '../storage';

type AuthData = {
  token: string;
  userId: number;
};

export type ApiContextValue = {
  client: AxiosInstance;
  userId?: number;
  authenticated: boolean | undefined;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
};

export const ApiContext = createContext<ApiContextValue>({
} as ApiContextValue);

const GUEST_ID = 0;
export const STORE_API_BASE = '/wc/store/v1';

type Props = {
  children: ReactNode;
};

export default function ApiProvider({ children }: Props): ReactElement {
  const [userId, setUserId] = useState<number>();
  const tokenRef = useRef<string>();

  const client = useMemo(() => axios.create({
    baseURL: '/cms/wp-json',
    withCredentials: true,
  }), []);

  useEffect(() => {
    client.interceptors.request.use((config) => {
      if(config.headers) {
        const token = tokenRef.current;
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
      }

      return config;
    });
  }, [client]);

  useEffect(() => {
    (async (): Promise<void> => {
      if(userId === undefined) {
        const storedToken = localStorage.getItem(StorageKeys.Token) || '';
        const storedUserId = localStorage.getItem(StorageKeys.UserId) || GUEST_ID;

        tokenRef.current = storedToken;
        setUserId(Number(storedUserId));
      }
    })();
  }, [userId]);

  const store = useCallback((items: AuthData | undefined) => {
    localStorage.setItem(StorageKeys.Token, items?.token ?? '');
    localStorage.setItem(StorageKeys.UserId, items?.userId ? `${items?.userId}` : `${GUEST_ID}`);
  }, []);

  const logout = useCallback(async () => {
    store(undefined);
    tokenRef.current = undefined;
    setUserId(GUEST_ID);
  }, [store]);

  useEffect(() => {
    client.interceptors.response.use(
      (response) => response,
      (error) => {
        if(error.response) {
          const axiosError = error as AxiosError;
          if(axiosError.response?.status === 403 && tokenRef.current) {
            logout();
          }
        }

        return Promise.reject(error);
      },
    );
  }, [client, logout]);

  const authenticated = useMemo(() => {
    if(userId === undefined) {
      return undefined;
    }

    return userId !== GUEST_ID;
  }, [userId]);
  

  const login = useCallback(async (username: string, password: string) => {
    try {
      const { data: authData } = await client.post<AuthData>('/jwt-auth/v1/token', {
        username,
        password,
      });
      store({
        token: authData.token,
        userId: authData.userId,
      });

      tokenRef.current = authData.token;
      setUserId(authData.userId);
    } catch {
      await Promise.reject({
        message: 'Die angegebenen Daten sind inkorrekt',
      });
    }
  }, [client, store]);

  const value = useMemo(() => ({
    client,
    userId,
    authenticated,
    login,
    logout,
  } as ApiContextValue), [client, userId, authenticated, login, logout]);

  return (
    <ApiContext.Provider value={value}>
      {children}
    </ApiContext.Provider>
  );
}
