import { Link } from 'gatsby';
import React, { ReactPortal, useContext } from 'react';
import { createPortal } from 'react-dom';
import useLiftedContent from '../shared/hooks/use-lifted-content';
import { TrackingContext } from '../shared/tracking/tracking-provider';
import './privacy.scss';

export default function Privacy(): ReactPortal | null {
  const { enabled, enable, disable } = useContext(TrackingContext);

  const target = useLiftedContent();

  return target && enabled === null ? createPortal((
    <div className="privacy">
      <header className="mb-4">
        <h3>Datenschutzhinweis</h3>
      </header>

      <div className="grid">
        <div className="col-12 col-8--xl block block--align-center">
          <p>
            Wir nutzen Cookies und andere Technologien auf unserer Website,
            um deine Erfahrung zu verbessern.
            Einige davon sind für den Betrieb der Website notwendig.
            Darüber hinaus kannst du Cookies für Statistik- und Marketingzwecke zulassen.
            Lese mehr in
            {' '}
            <Link to="/common/privacy">
              unserer Datenschutzerklärung
            </Link>
            .
          </p>
        </div>

        <div className="privacy__actions col-12 col-4--xl">
          <button
            type="button"
            className="button button--beige-light"
            onClick={enable}
          >
            Alle
          </button>
          <button
            type="button"
            className="button button--beige-light ml-3"
            onClick={disable}
          >
            Essentielle
          </button>
        </div>
      </div>
    </div>
  ), target) : null;
}
