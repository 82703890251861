import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactElement, useMemo, useState } from 'react';
import NewsletterForm, { NewsletterFormVariant } from '../newsletter/newsletter-form/newsletter-form';
import './footer.scss';

export default function Footer(): ReactElement {
  const [subscribed, setSubscribed] = useState(false);

  const data = useStaticQuery<Queries.FooterQuery>(graphql`query Footer {
    wp {
      generalSettings {
        title
      }
    }
    allWpBrand(sort: {menuOrder: ASC}) {
      nodes {
        brandFooter {
          title
          links {
            title
            target
          }
        }
      }
    }
  }`);
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="site-footer__cols grid grid--gapless">
          <div className="site-footer__col col-12 col-6--md col-3--xl">
            <h3>
              Hey, join the club!
            </h3>

            <p className="mt-4">
              {subscribed ? (
                'Genial! Ab sofort bist du immer auf dem neusten Stand!'
              ) : (
                <>
                  <strong>Unser Newsletter:</strong>
                  {' '}
                  Sei immer auf dem neusten Stand!
                </>
              )}
            </p>

            {!subscribed && (
              <NewsletterForm
                className="mt-4"
                variant={NewsletterFormVariant.White}
                onSubscribed={setSubscribed.bind(null, true)}
              />
            )}
          </div>

          {data.allWpBrand.nodes.map((brand) => (
            <div
              key={brand.brandFooter?.title}
              className="site-footer__col col-12 col-6--md col-3--xl"
            >
              <h4>{brand.brandFooter?.title}</h4>

              <ul className="site-footer__links list--unstyled">
                {brand.brandFooter?.links?.map((link) => (
                  <li key={link?.title}>
                    <a href={link?.target ?? undefined} target="_blank" rel="noreferrer">
                      {link?.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <div className="site-footer__col col-12 col-6--md col-3--xl">
            <h4>Allgemein</h4>

            <ul className="site-footer__links list--unstyled">
              <li>
                <a href="/contact">Kontakt</a>
              </li>
              <li>
                <a href="/common/shipping-and-payment">Versand & Zahlung</a>
              </li>

              <li className="mt-7">
                <a href="/common/terms-and-conditions">AGB</a>
              </li>
              <li>
                <a href="/common/privacy">Datenschutz</a>
              </li>
              <li>
                <a href="/common/recall">Widerruf</a>
              </li>
              <li>
                <a href="/common/impressum">Impressum</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="site-footer__copyright">
          <small>
            Copyright &copy; {data.wp?.generalSettings?.title} {year} - Alle Rechte vorbehalten.
          </small>
        </div>
      </div>
    </footer>
  );
}
