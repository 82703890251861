import React, { ReactElement } from 'react';
import { Currency } from '../api/types';

type Props = {
  currency?: Currency;
  value?: string;
};

export function addToPrice(
  source: string | undefined = '0',
  amount: string | undefined = '0',
): string {
  return `${Number(source) + Number(amount)}`;
}

export function getPriceAmount(value?: string, currency?: Currency): string {
  if(!value || !currency) return '0';

  let preDecimal = '0';
  if(value.length > currency.currency_minor_unit) {
    preDecimal = value.slice(0, value.length - currency.currency_minor_unit);
  }

  let decimal = value.slice(-currency.currency_minor_unit);
  if(decimal.length < currency.currency_minor_unit) {
    const prefix = '0'.repeat(currency.currency_minor_unit - decimal.length);
    decimal = `${prefix}${decimal}`;
  }

  return `${preDecimal}${currency.currency_decimal_separator}${decimal}`;
}

export default function Price({
  currency,
  value,
}: Props): ReactElement | null {
  if(!currency || !value) {
    return null;
  }

  return <>{getPriceAmount(value, currency)}{' '}{currency.currency_symbol}</>;
}
