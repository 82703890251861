import { useCallback, useContext, useMemo } from 'react';
import { CartItem, Coupon, Currency } from '../api/types';
import { getPriceAmount } from '../price/price';
import { TrackingContext } from './tracking-provider';
import { AddItemEvent, CheckoutEvent, PurchaseEvent, SearchEvent, TrackingCoupon, TrackingEventType, TrackingItem, ViewCategoryEvent, ViewPageEvent, ViewProductEvent } from './types';
import uppr, { PageType } from './uppr';
import { getItemData } from '../api/utils';

type TrackingEventMapping = { [key in TrackingEventType]?: string };

const FacebookEvent: TrackingEventMapping = {
  [TrackingEventType.ViewProduct]: 'ViewContent',
  [TrackingEventType.AddItem]: 'AddToCart',
  [TrackingEventType.Checkout]: 'InitiateCheckout',
  [TrackingEventType.Purchase]: 'Purchase',
  [TrackingEventType.Subscribe]: 'Lead',
};

const GoogleEvent: TrackingEventMapping = {
  [TrackingEventType.Purchase]: `${process.env.GATSBY_GOOGLE_TAG_ID}/${process.env.GATSBY_GOOGLE_CONVERSION_LABEL_PURCHASE}`,
};

const PinterestEvent: TrackingEventMapping = {
  [TrackingEventType.ViewCategory]: 'viewcategory',
  [TrackingEventType.ViewProduct]: 'pagevisit',
  [TrackingEventType.AddItem]: 'addtocart',
  [TrackingEventType.Purchase]: 'checkout',
  [TrackingEventType.Subscribe]: 'lead',
  [TrackingEventType.Search]: 'search',
};

const currencyCode = 'EUR';

type TrackingValue = {
  getTrackingPrice: (value?: string, currency?: Currency) => number;
  getTrackingItem: (cartItem?: CartItem) => TrackingItem;
  getTrackingItems: (cartItems?: CartItem[]) => TrackingItem[];
  getTrackingCoupons: (coupons?: Coupon[]) => TrackingCoupon[];
  trackViewCategory: (event: ViewCategoryEvent) => void;
  trackViewPage: (event: ViewPageEvent) => void;
  trackViewProduct: (event: ViewProductEvent) => void;
  resetViewTracking: () => void;
  trackAddItem: (event: AddItemEvent) => void;
  trackCheckout: (event: CheckoutEvent) => void;
  trackPurchase: (event: PurchaseEvent) => void;
  trackSubscribe: () => void;
  trackSearch: (event: SearchEvent) => void;
};

export default function useTracking(): TrackingValue {
  const {
    enabled,
    setProductSku,
    setPageType,
  } = useContext(TrackingContext);

  const getTrackingPrice = useCallback((value?: string, currency?: Currency) => {
    return Number(getPriceAmount(value, currency));
  }, []);

  const getTrackingItem = useCallback((cartItem?: CartItem): TrackingItem => {
    const taxonomyData = getItemData(cartItem?.item_data, 'taxonomy');
    const upprTrigger = taxonomyData?.uppr;

    return {
      id: cartItem?.id ?? -1,
      itemNumber: cartItem?.sku ?? '',
      name: cartItem?.name ?? '',
      category: taxonomyData?.category ?? '',
      quantity: cartItem?.quantity ?? 0,
      price: getTrackingPrice(cartItem?.prices.price, cartItem?.prices),
      taxonomies: {
        uppr: upprTrigger ? [upprTrigger] : [],
      },
    };
  }, [getTrackingPrice]);

  const getTrackingItems = useCallback((cartItems?: CartItem[]): TrackingItem[] => {
    return cartItems?.map((item) => getTrackingItem(item)) ?? [];
  }, [getTrackingItem]);

  const getTrackingCoupon = useCallback((coupon?: Coupon): TrackingCoupon => {
    return {
      code: coupon?.code ?? '',
      discount: getTrackingPrice(coupon?.totals.total_discount, coupon?.totals),
    };
  }, [getTrackingPrice]);

  const getTrackingCoupons = useCallback((coupons?: Coupon[]) => {
    return coupons?.map((coupon) => getTrackingCoupon(coupon)) ?? [];
  }, [getTrackingCoupon]);

  const trackViewCategory = useCallback((event: ViewCategoryEvent) => {
    if(!enabled) return;

    pintrk?.('track', PinterestEvent[TrackingEventType.ViewCategory]!, {
      product_category: event.name,
    });
  }, [enabled]);

  const trackViewPage = useCallback((event: ViewPageEvent) => {
    if(!enabled) return;

    switch(event.slug) {
      case 'knut-hansen':
        setPageType(PageType.KnutHansen);
        break;
      case 'ron-piet':
        setPageType(PageType.RonPiet);
        break;
      case 'merch':
        setPageType(PageType.Merch);
        break;
      case 'all':
        setPageType(PageType.All);
        break;
      default:
    }
  }, [enabled, setPageType]);

  const trackViewProduct = useCallback((event: ViewProductEvent) => {
    if(!enabled) return;

    const type = TrackingEventType.ViewProduct;

    fbq?.('track', FacebookEvent[type]!, {
      content_type: 'product',
      contents: event,
      value: event.price,
      currency: currencyCode,
    });

    pintrk?.('track', PinterestEvent[type]!, {
      property: event.itemNumber,
    });

    setProductSku(event.itemNumber);
  }, [enabled, setProductSku]);

  const resetViewTracking = useCallback(() => {
    setProductSku(undefined);
    setPageType(undefined);
  }, [setPageType, setProductSku]);

  const trackAddItem = useCallback((event: AddItemEvent) => {
    if(!enabled) return;

    const type = TrackingEventType.AddItem;

    fbq?.('track', FacebookEvent[type]!, {
      content_type: 'product',
      contents: event,
      value: event.price * event.quantity,
      currency: currencyCode,
    });

    pintrk?.('track', PinterestEvent[type]!, {
      value: event.price * event.quantity,
      order_quantity: event.quantity,
      currency: currencyCode,
    });
  }, [enabled]);

  const trackCheckout = useCallback((event: CheckoutEvent) => {
    if(!enabled) return;

    fbq?.('track', FacebookEvent[TrackingEventType.Checkout]!, {
      content_type: 'product',
      contents: event.items,
      value: event.total,
      currency: currencyCode,
    });
  }, [enabled]);

  const trackPurchase = useCallback((event: PurchaseEvent) => {
    if(!enabled) return;

    const type = TrackingEventType.Purchase;

    fbq?.('track', FacebookEvent[type]!, {
      content_type: 'product',
      contents: event.items,
      value: event.total,
      currency: currencyCode,
    });

    gtag?.('event', 'conversion', {
      send_to: GoogleEvent[type],
      value: event.total,
      currency: currencyCode,
    });

    uppr(event);

    pintrk?.('track', PinterestEvent[type]!, {
      value: event.total,
      order_quantity: event.items.length,
      currency: currencyCode,
    });
  }, [enabled]);

  const trackSubscribe = useCallback(() => {
    if(!enabled) return;

    const type = TrackingEventType.Subscribe;

    fbq?.('track', FacebookEvent[type]!);

    pintrk?.('track', PinterestEvent[type]!, {
      lead_type: 'Newsletter',
    });
  }, [enabled]);

  const trackSearch = useCallback((event: SearchEvent) => {
    if(!enabled) return;

    pintrk?.('track', PinterestEvent[TrackingEventType.Search]!, {
      search_query: event.query,
    });
  }, [enabled]);

  return useMemo(() => ({
    getTrackingPrice,
    getTrackingItem,
    getTrackingItems,
    getTrackingCoupons,
    trackViewCategory,
    trackViewPage,
    trackViewProduct,
    resetViewTracking,
    trackAddItem,
    trackCheckout,
    trackPurchase,
    trackSubscribe,
    trackSearch,
  }), [
    getTrackingPrice,
    getTrackingItem,
    getTrackingItems,
    getTrackingCoupons,
    trackViewCategory,
    trackViewPage,
    trackViewProduct,
    resetViewTracking,
    trackAddItem,
    trackCheckout,
    trackPurchase,
    trackSubscribe,
    trackSearch,
  ]);
}
