import React, { ReactElement } from 'react';
import { CartItem } from '../../shared/api/types';
import { getItemData } from '../../shared/api/utils';
import classes from '../../shared/classes';
import Price, { addToPrice } from '../../shared/price/price';
import Rendered from '../../shared/rendered';
import './cart-drawer-notification.scss';
import { Link } from '@gatsbyjs/reach-router';

type Props = {
  item: CartItem;
  className?: string;
  removeNewestItem: (params: any) => any;
};

export default function CartDrawerNotification({
  item,
  className,
  removeNewestItem,
}: Props): ReactElement {
  const brand = getItemData(item.item_data, 'brand');
  const attributes = item.variation.map((attribute) => attribute.value);

  return (
    <div className={classes('cart-drawer-notification-host', className)}>
      <div className={classes('cart-drawer-notification', className)}>
        <header className="flex justify-between items-center pb-3 border-bottom">
          <p className="text-title sm:text-sm text-sm">Warenkorb aktualisiert</p>
          <div className=''>
            <button onClick={() => removeNewestItem(undefined)} className='button button--unstyled block_'>
              <i className='icon icon--times icon--small'>close</i>
            </button>
          </div>
        </header>

        <div className="block block--justify-between mt-3">
          <div className="block block--vertical">
            <p className="text-bold">
              {item.quantity}x
              {' '}
              <span className="text-uppercase">{brand?.title}</span>
              {' - '}
              <Rendered html={item.name} wrapper="span" />
              {!!attributes.length && ` - ${attributes.join(', ')}`}
            </p>

            <div className="mt-3">
              <Price
                value={addToPrice(item.totals.line_total, item.totals.line_total_tax)}
                currency={item.totals}
              />
            </div>
          </div>

          <div className="ml-3">
            <img
              srcSet={item.images[0].srcset}
              alt={`${item.name} Bild`}
              className="cart-drawer-notification__image"
            />
          </div>
        </div>

        <footer className='w-full mt-4 flex flex-col justify-center gap-3'>
            <Link onClick={() => removeNewestItem(undefined)} className='button w-full' to="checkout/customer">Zur Kasse</Link>
            <button onClick={() => removeNewestItem(undefined)} className='button button--unstyled'>Weiter einkaufen</button>
          </footer>
      </div>
    </div>
  );
}
